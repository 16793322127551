import { render, staticRenderFns } from "./artistIntroduction.vue?vue&type=template&id=684c34f0&scoped=true&"
import script from "./artistIntroduction.vue?vue&type=script&lang=js&"
export * from "./artistIntroduction.vue?vue&type=script&lang=js&"
import style0 from "./artistIntroduction.vue?vue&type=style&index=0&id=684c34f0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684c34f0",
  null
  
)

export default component.exports